
window.HorizontalPhotoSlider = (randomId) => {
	return {
		swiper: null,
		init() {
			setTimeout(() => {
				this.swiper = new Swiper('.swiper-container'+randomId, {
					slidesPerView: 'auto',
					loop: true,
					spaceBetween: 4,
					autoplay: {
						delay: 0,
						disableOnInteraction: false,
					},
					speed: 5000,
				})
			}, 0);
		}
	};
};
